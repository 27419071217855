import React, {useContext} from 'react';
import { Link } from "react-router-dom";
import { UserContext } from '../../Contexts/UserContext';
import { useNavigate } from "react-router";
import Role from '../../Helpers/Role';

const UserProfileLeftNav = (props) => {
    const {user, signOut} = useContext(UserContext);
    const navigate = useNavigate();

    return (
        <div className="left-nav">
            <a className="profile-back-link" onClick={() => navigate(-1)}><i></i> Back</a>
            <ul>
                <li><Link to="/profile" className={props.section == "profile" ? "active" : ""}>Profile</Link></li>
                <li><Link to="/settings" replace className={props.section == "settings" ? "active" : ""}>Settings</Link></li>
                {
                    Role.isAdmin(user.role) && 
                    <>
                        <li><Link to="/members" replace className={props.section == "members" ? "active" : ""}>Members</Link></li>
                        {
                            !user.isClientUser &&
                            <li><Link to="/manageevents?view=0" replace className={props.section == "manageevents" ? "active" : ""}>Manage Events</Link></li>
                        }
                    </>
                }
                <li><Link to="/clearcache" replace className={props.section == "clearcache" ? "active" : ""}>Clear Cache</Link></li>
                <li className="logout"><Link to="/" onClick={()=> signOut()}>Log Out</Link></li>
            </ul>
        </div>
    );
}

export default UserProfileLeftNav;