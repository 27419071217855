import { useRef, useCallback, useEffect, useContext, useState } from 'react';
import { GoogleMap, Polygon, GroundOverlay, InfoWindow, Rectangle, Polyline, DrawingManager, Marker } from '@react-google-maps/api';
import toast from 'react-hot-toast';
import { fromUrl } from 'geotiff';
import geokeysToProj4 from "geotiff-geokeys-to-proj4";
import proj4 from 'proj4';
import * as uuid from "uuid";
import DeleteIcon from '../../Assets/img/icon-delete-annotations.svg';
import EditAnnotationIcon from '../../Assets/img/icon-draw-geometry-edit.svg';
import PolygonIcon from '../../Assets/img/icon-polygon-draw.svg';
import SquareIcon from '../../Assets/img/icon-square-draw.svg';
import HandIcon from '../../Assets/img/icon-hand.svg';
import RecenterIcon from '../../Assets/img/icon-recenter-map.svg';
import { UserContext } from '../../Contexts/UserContext';
import { apiService } from '../../Services/apiService';
import { eventService } from '../../Services/eventService';
import { MapUtils } from '../MapUtils';
import { AOIOptions } from '../AOIOptions';
import DATools from '../DATools/DATools';
import SaveAnnotationModal from '../DATools/SaveAnnotationModal';
import ImageAnnotationModal from '../DATools/ImageAnnotationModal';
import * as turf from '@turf/turf';
import LocationSearch from '../AOI/LocationSearch';
import { getGeocode } from 'use-places-autocomplete';
import Role from '../../Helpers/Role';

const landuseMap = {
    0: "Water",
    1: "Trees",
    2: "Grass",
    3: "Flooded Vegetation",
    4: "Crops",
    5: "Shrub & Scrub",
    6: "Built Area",
    7: "Bare Ground",
    8: "Snow & Ice",
};

const defaultPipelineOptions = {
    fillColor: "lightblue",
    fillOpacity: 0,
    strokeColor: "#FFC700",
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
};


const SingleTimeline = (props) => {
    const mapRef = useRef();
    const geometryAnnotationRef = useRef();
    const [sourceImageUrl, setSourceImageUrl] = useState("");
    const [sourceUrls, setSourceUrls] = useState([]);
    const [targetImageUrl, setTargetImageUrl] = useState("");
    const [infoOpen, setInfoOpen] = useState(false); 
    const [label, setLabel] = useState("");
    const [position, setPosition] = useState(props.position);
    const [aoiImage, setAoiImage] = useState(null);
    const [mapOptions, setMapOptions] = useState(AOIOptions.roadMapOptions);
    const {user, getImageFromDb, storeImageInDb,
        mapMovementPoints, setMapMovementPoints,
        mapMovementPointPosition, setMapMovementPointPosition,
        mapMoveState, setMapMoveState,
        setShowMapMoveOptions
    } = useContext(UserContext);
    const [aoiOptions, setAoiOptions] = useState({...AOIOptions.userAOIoptions});
    const [annotationsOptions, setAnnotationsOptions] = useState({...AOIOptions.rectangleAnnotationOptions});
    const [showCreateRectangleAnnotation, setShowCreateRectangleAnnotation] = useState(false);
    const [initialAnnotationGeometry, setInitialAnnotationGeometry] = useState([]);
    const [showSaveAnnotationModal, setShowSaveAnnotationModal] = useState(false);
    const [showImageAnnotationModal, setShowImageAnnotationModal] = useState(false);
    const [allAnnotations, setAllAnnotations] = useState([]);
    const [annotation, setAnnotation] = useState({});
    const [geometryAnnotationMap, setGeometryAnnotationMap] = useState({});
    const [geometryAnnotationInfoMap, setGeometryAnnotationInfoMap] = useState({});
    const [annotationMode, setAnnotationMode] = useState(null);
    const [showListAnnotationsModal, setShowListAnnotationsModal] = useState(false);
    const [imageAnnotation, setImageAnnotation] = useState(null);
    const [showAOIImage, setShowAOIImage] = useState(true);
    // Disable show annotations in single view & Don't show annotations on the left side image. 
    const [showMilestones, setShowMilestones] = useState(false);
    const [reRenderAOIAnnotations, setReRenderAOIAnnotations] = useState(false);
    const [showAOIBoundary, setShowAOIBoundary] = useState(true);
    const [overlays, setOverlays] = useState([]);
    const [drawingMode, setDrawingMode] = useState(null);

    const [pipelineOptions, setPipelineOptions] = useState({firstOptions: defaultPipelineOptions});
    const [rou50mOptions, setRou50mOptions] = useState({firstOptions: defaultPipelineOptions});
    const dashboardSettings = user.userSettings?.dashboard ?? AOIOptions.getDefaultDashboardSettings();

    useEffect(() => {
        setShowMapMoveOptions(true);

        //let dashboardSettings = user.userSettings?.dashboard ?? AOIOptions.getDefaultDashboardSettings();

        setPipelineOptions(AOIOptions.getPipelineSettings(dashboardSettings.pipelineSettings));
        setRou50mOptions(AOIOptions.getPipelineSettings(dashboardSettings.rou50mSettings));
        setAoiOptions({...aoiOptions, strokeColor: dashboardSettings.aoiBorderColor, strokeWeight: dashboardSettings.lineThickness});
        setAnnotationsOptions({...annotationsOptions, strokeWeight: Number.parseInt(dashboardSettings.lineThickness)});

        const updatedRoadMapOptions = {
            ...mapOptions, // Spread the original options
            styles: mapOptions.styles.map(style => {
                if (style.featureType === "water") {
                // Update water color
                return { ...style, stylers: [{ color: dashboardSettings.mapSettings.water }] };
                } else if (style.featureType === "landscape") {
                // Update landscape color
                return { ...style, stylers: [{ color: dashboardSettings.mapSettings.landscape }] };
                }
                return style; // Return the style unchanged if it does not match the conditions
            })
            };
        setMapOptions(updatedRoadMapOptions);

        return () => setShowMapMoveOptions(false);
    }, []);

    useEffect(() => {
        saveMapState();
    }, [props.selectedTimelineView]);

    useEffect(() => {
        setMapOptions((options) => ({...options, zoomControl: props.showZoom }));
    }, [props.showZoom]);

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
        if(props.onMapLoad)
            props.onMapLoad(map);          
    }, []);    

    // useEffect(()=> {
    //     fitMap();
    // }, [props.aoi, mapRef, mapRef.current]);

    const fitMap = () => {
        props.setSelectedAnnotationId("");
        MapUtils.fitBoundsGeometry(props.aoi?.aoiGeometry, mapRef);
        updateThickness();
    };

    const onZoomChange = () => {
        // if(mapRef.current && mapRef.current.zoom){
        //     var thickness = 1;
        //     console.log("zoom level ", mapRef.current.zoom);
        //     for(var i=0; i<user.userPreferences.GailSettings?.thickness?.length; i++) {
        //         if(mapRef.current.zoom < user.userPreferences.GailSettings.thickness[i]) {
        //             continue;
        //         }
        //         thickness++;
        //     }
        //     setAoiOptions({...aoiOptions,strokeWeight: thickness});
        //     setRou50mOptions({...rou50mOptions, strokeWeight: thickness});
        //     setPipelineOptions({...pipelineOptions, strokeWeight: thickness});
        // }
    };

    const saveMapState = () => {
        if (mapRef.current) {
            props.setMapState({center: mapRef.current.getCenter(), zoom: mapRef.current.zoom});
        }
    }

    const updateThickness = () => {
        if(mapRef.current && mapRef.current.zoom){
            var thickness = 1;
            console.log("zoom level ", mapRef.current.zoom);
            for(var i=0; i<dashboardSettings?.thickness?.length; i++) {
                if(mapRef.current.zoom < dashboardSettings?.thickness[i]) {
                    continue;
                }
                thickness++;
            }
            setAoiOptions(value => ({...value, strokeWeight: thickness}));
            setAnnotationsOptions(value => ({...value, strokeWeight: thickness}));

            setRou50mOptions(value => ({...value, 
                firstOptions: {...value.firstOptions, strokeWeight: thickness},
                secondOptions: {...value.secondOptions, strokeWeight: thickness + value.secondOptions?.additionalThickness ?? 0}
            }));
            setPipelineOptions(value => ({...value, 
                firstOptions: {...value.firstOptions, strokeWeight: thickness},
                secondOptions: {...value.secondOptions, strokeWeight: thickness + value.secondOptions?.additionalThickness ?? 0}
            }));
        }
    };

    useEffect(()=> {
        if(props.timeline) {
            setSourceUrls([]);
            updateThickness();
            setAoiImage(null);
            setLabel("");
            props.timeline.images.map((im) => {
                getTimelineImageUrl(im.imagePath, (url) => {
                    setSourceUrls((prevUrls) => [...prevUrls, {url: url, bounds: {maxx: im.maxx, maxy: im.maxy, minx: im.minx, miny: im.miny}}]);
                });
            });
        }
    }, [props.timeline]);

    // useEffect(async()=>{
    //     renderLabel(props.info, props.position);   
    // },[props.info, props.position]);

    useEffect(() => {
        getAllAnnotations();
    }, [props.timeline]);

    useEffect(async()=>{
        if (allAnnotations.length > 0 && props.selectedAnnotationId != "") {
            const annotation = allAnnotations.find(a => a.id == props.selectedAnnotationId);
            if (annotation) {
                props.setShowAOIAnnotations(true);
                props.setSelectedAnnotationId(""); // Reset the selected annotation after displaying selected annotation
                setTimeout(() => {
                    MapUtils.fitBoundsSelectectedAOI(annotation, mapRef);
                    if(mapRef.current.zoom > 19)
                        mapRef.current.setZoom(19);
                    updateThickness();
                }, 1000);
            }
        }
        else {
            updateThickness();
        }

        const existingImageAnnotation = allAnnotations.find(annotation => annotation.type == "markerjs") ?? null;
        setImageAnnotation({...existingImageAnnotation});
        if (existingImageAnnotation && existingImageAnnotation.markerjs_json?.annotated_image_path) {
            setAnnotatedImage(existingImageAnnotation.markerjs_json.annotated_image_path ?? null);
        }
        else {
            setTargetImageUrl(null);
        }
    }, [allAnnotations, props.selectedAnnotationId]);

    useEffect(() => {
        if (reRenderAOIAnnotations) {
            props.setShowAOIAnnotations(true);
            setReRenderAOIAnnotations(false);
        }
    }, [reRenderAOIAnnotations]);

    const getCoordinates = (timeline) => {
        var coordinates = new google.maps.LatLngBounds(
            new google.maps.LatLng(timeline.miny, timeline.minx),
            new google.maps.LatLng(timeline.maxy, timeline.maxx)
        );
        return coordinates;
    };

    //const latLngToMapCoords = (projObj, latLng) => proj4(projObj.proj4, [latLng[0], latLng[1]]);

    const fullUrl = (url) => {
        var dataUrl = apiService.getDataUrl(user.region);
        return ((dataUrl.endsWith("/") && !url.startsWith("/")) || (!dataUrl.endsWith("/") &&  url.startsWith("/")))
        ? `${dataUrl}${url}` :
        ((dataUrl.endsWith("/") &&  url.startsWith("/")) ? `${dataUrl}${url.slice(1)}` : `${dataUrl}/${url}`)
    }

    // const isImageAndLabelOfEqualSize = (imgBounds, labelBoundsMapCoords, projObj) => {
    //     let imgBoundsMapCoords = [].concat(
    //         latLngToMapCoords(projObj, [imgBounds["minx"], imgBounds["miny"]]),
    //         latLngToMapCoords(projObj, [imgBounds["maxx"], imgBounds["maxy"]])
    //     );
    //     let diff = imgBoundsMapCoords.map((value, index) => value - labelBoundsMapCoords[index]);
    //     const checkDiff = diff.some((d)=>{return Math.abs(d)>30});        
    //     return !checkDiff;
    // }

    // const renderLabel= async (openInfoWin, latLng)=>{
    //     if(!props.drawInfo)
    //         return;
    //     if(openInfoWin){
    //         if(aoiImage){
    //             const labelBboxMapCoords = aoiImage.getBoundingBox();
    //             const geoKeys = aoiImage.getGeoKeys(); // Get geokeys
    //             const projObj = geokeysToProj4.toProj4(geoKeys); // Convert geokeys to proj4 string
    //             const rgbImgBounds = props.timeline.result.bounds;
    //             if(isImageAndLabelOfEqualSize(rgbImgBounds,labelBboxMapCoords,projObj)){
    //                 let point = proj4(projObj.proj4, [latLng.lng, latLng.lat]);
    //                 let xPx = Math.floor((point[0] - labelBboxMapCoords[0])/10);
    //                 let yPx = Math.floor((point[1] - labelBboxMapCoords[1])/10);
    //                 const window = [ xPx, yPx, xPx + 1, yPx + 1 ];
    //                 const data = await aoiImage.readRasters({window});
    //                 setLabel(landuseMap[data[0]]);
    //                 setPosition(latLng); 
    //                 setInfoOpen(true);
    //             }
    //             else {
    //                 setInfoOpen(false);
    //             }
    //         }
    //     } else {
    //         setInfoOpen(false);  
    //     }
    // }
 
    // const aoiClickHandler = async(event) => {
    //     if(!props.drawInfo)
    //         return;
    //     if(props.clickHandler) {
    //         props.clickHandler(true,{"lat": event.latLng.lat(), "lng": event.latLng.lng()});
    //     } else {
    //         await renderLabel(true,{"lat": event.latLng.lat(), "lng": event.latLng.lng()});
    //     }      
    // }

    const handleInfoWindowClose = () => {
        if(!props.drawInfo)
            return;
        if(props.clickHandler)
            props.clickHandler(false);
        setInfoOpen(false);
    }

    const getTimelineImageUrl = (url, setValue) => {
        getImageFromDb(url, (data) => {
            if(data == null) {
                getTimelineImage(url, (blob) => {
                    const blobUrl = window.URL.createObjectURL(blob);
                    setValue(blobUrl);
                    storeImageInDb(url, blob);
                });
            } else {
                const blobUrl = window.URL.createObjectURL(data);
                setValue(blobUrl);
            }
        });
    };

    const getTimelineImage = (url, callback) => {
        const requestOptions = {
            method: 'GET'
        }; 
        fetch(url, requestOptions).then((response)=>{
            response.blob().then(blob => callback(blob))
            .catch((e) => console.error(e));
        })
        .catch((e) => console.error(e));
    };

    const setImage = async (url) => {
        const completeUrl = fullUrl(url);
        const requestOptions = {
            method: 'GET',
            headers: {'Authorization': `Bearer ${(window.authToken)}`}
        }; 
        const tiff = await fromUrl(completeUrl, requestOptions);
        const image = await tiff.getImage();
        setAoiImage(image);
    }

    const drawGeometryAnnotation = (shape) => {
        setAnnotationMode('create');
        setAnnotation({...annotation, text: "", type: 'geometry'});
        let initalGeometryCoordinates = null;
        if (shape == 'rectangle') {
            setShowCreateRectangleAnnotation(true);
            initalGeometryCoordinates = getInitialRectBounds();
        }
        setInitialAnnotationGeometry(initalGeometryCoordinates);
    }

    const getInitialRectBounds = () => {
        const map = mapRef.current;
        const center = map.getCenter();
        const size = new google.maps.Size(35,35);
        const n = google.maps.geometry.spherical.computeOffset(center, size. height/2,0).lat();
        const s = google.maps.geometry.spherical.computeOffset(center, size. height/2,180).lat();
        const e = google.maps.geometry.spherical.computeOffset(center, size. width/2,90).lng();
        const w = google.maps.geometry.spherical.computeOffset(center, size. width/2,270).lng();

        return {north: n, south: s, east: e, west: w};
    }

    const onEditAnnotation = (annotation) => {
        if (annotation) {
            setAnnotationMode('edit');
            setAnnotation({...annotation});
            setShowSaveAnnotationModal(true);
            // close the info window
            // let geometryInfo = geometryAnnotationInfoMap[annotation.id];
            // geometryInfo.close();

            // set the geometry ref
            // let geometry = geometryAnnotationMap[annotation.id];
            // geometry.setEditable(true);
            // geometry.setDraggable(true);
            // setGeometryAnnotationRef(geometry);
        }
    }

    const setGeometryAnnotationRef = (geometryAnnotation) => {
        geometryAnnotationRef.current = geometryAnnotation;
    }

    const geometryAnnotationDiscard = () => {
        if (annotationMode == 'edit') {
            let geometry = geometryAnnotationMap[annotation.id];       
            if (!!geometry) {
                let bounds = getBoundsFromGeometry(annotation.geometry);
                if (!!bounds) {
                    geometry.setBounds(bounds);
                }
                geometry.setEditable(false);
                geometry.setDraggable(false);
            }  
        }
        setGeometryAnnotationRef(null);
        setAnnotationMode(null);
        setShowCreateRectangleAnnotation(false);
    }

    const getCoordinatesOfGeometry = () => {
        const shape = geometryAnnotationRef.current;
        const bounds = shape.getBounds();
        const NE = bounds.getNorthEast();
        const SW = bounds.getSouthWest();
        const NW = new google.maps.LatLng(NE.lat(),SW.lng());
        const SE = new google.maps.LatLng(SW.lat(),NE.lng());
        let coordinates = [];
        coordinates.push([NE.lng(), NE.lat()]);
        coordinates.push([SW.lng(), SW.lat()]);
        coordinates.push([NW.lng(), NW.lat()]);
        coordinates.push([SE.lng(), SE.lat()]);
        return [coordinates];
    }

    const getPathsFromGeometry = (geometry) => {
        const coordinates = geometry?.coordinates ?? [];
        let bounds = [];
        coordinates[0]?.forEach((lnglat) => {
            bounds.push({lat: lnglat[1], lng: lnglat[0]});
        });
        return bounds;
    };

    const getBoundsFromGeometry = (geometry) => {
        const coordinates = geometry?.coordinates ?? [];
        let bounds = new google.maps.LatLngBounds();
        coordinates[0]?.forEach((lnglat) => {
            bounds.extend({lat: lnglat[1], lng: lnglat[0]});
        });
        return bounds;
    };

    const getNorthEastFromGeometry = (geometry) => {
        const coordinates = geometry?.coordinates ?? [];
        let corner = {lat: coordinates[0][0][1], lng: coordinates[0][0][0]};
        coordinates[0]?.forEach((lnglat) => {
            if(corner.lat < lnglat[1] || (corner.lat == lnglat[1] && corner.lng < lnglat[0]))
                corner = {lat: lnglat[1], lng: lnglat[0]}; 
        });
        return new google.maps.LatLng(corner.lat, corner.lng);
    };

    const getCenterFromGeometry = (geometry) => {
        let bounds = getBoundsFromGeometry(geometry);
        return bounds.getCenter();
    };

    const saveGeometryAnnotation = (coordinates) => {
        setAnnotation({...annotation, text: "", type: 'geometry', geometry: {type: "Polygon", coordinates: coordinates}});
        setShowSaveAnnotationModal(true);
    };

    const geometryAnnotationSave = () => {
        const coordinates = getCoordinatesOfGeometry();
        setAnnotation({...annotation, geometry: {type: "Polygon", coordinates: coordinates}});
        setShowSaveAnnotationModal(true);
    }

    const getAllAnnotations = async () => {
        if (!!props.timeline) {
            await eventService.getAnnotations(user.region, props.timeline.eventGuid)
            .then((data) => {
                setAllAnnotations([...data]);
            })
            .catch((e) => {
                console.error(e);
                toast.error("Unable to fetch the Annotations");
            });
        }
    }

    const saveAnnotation = async (annotationText) => {
        let annotationData = {
            type: annotation.type ?? "simple",
            text: annotationText ?? annotation.text,
        }
        if (annotation.type == "geometry") {
            annotationData.geometry = annotation.geometry;
        }

        if (annotationMode == 'create') {
            await eventService.createAnnotation(user.region, props.timeline.eventGuid, user.authId, annotationData)
            .then((result) => {
                toast.success('Annotation created successfully');
            })
            .catch((e) => {
                console.error(e);
                toast.error("Unable to create the Annotation");
            });
        }
        else if (annotationMode == 'edit') {
            await eventService.editAnnotation(user.region, props.timeline.eventGuid, user.authId, annotation.id, annotationData)
            .then((result) => {
                toast.success('Annotation updated successfully');
            })
            .catch((e) => {
                console.error(e);
                toast.error("Unable to update the Annotation");
            });
        }
        setShowSaveAnnotationModal(false);
        // if (annotation.type == "geometry") {
        //     geometryAnnotationDiscard();
        // }
        // if (annotation.type == "simple") {
        //     setShowListAnnotationsModal(true);
        // }
           
        //  Refresh Annotations
        getAllAnnotations();

        clearPolygons();
        setDrawingMode(null);

        // Open Annotation Info window once created/updated the annotation
        const infoWindow = geometryAnnotationInfoMap[annotation.id];
        if (!!infoWindow)
            infoWindow.open(mapRef.current);
    }

    const deleteAnnotation = async (annotationId) => {
        await eventService.deleteAnnotation(user.region, props.timeline.eventGuid, user.authId, annotationId)
        .then((result) => {
            getAllAnnotations();
            toast.success('Annotation deleted successfully');
        })
        .catch((e) => {
            console.error(e);
            toast.error("Unable to delete the Annotation");
        });

        if (showImageAnnotationModal) {
            setShowImageAnnotationModal(false);
        }
    }

    const onGeometryAnnotationLoad = (geometry, annotation) => {
        return setGeometryAnnotationMap(prevState => {
            return { ...prevState, [annotation.id]: geometry };
        })
    }

    const onInfoWindowLoad = (infoWindow, annotation) => {
        return setGeometryAnnotationInfoMap(prevState => {
            return { ...prevState, [annotation.id]: infoWindow };
        })
    }

    const onGeometryAnnotationClick = (annotation) => {
        const infoWindow = geometryAnnotationInfoMap[annotation.id];
        infoWindow.open(mapRef.current);
    }

    const onImageAnnotationClick = () => {
        if (!imageAnnotation?.type) {
            setImageAnnotation({type: "markerjs"});
        }
        setShowImageAnnotationModal(true);
    }

    const saveImageAnnotation = async (markerState, markerImage) => {
        if (!markerImage) {
            toast.error("Accept the changes before saving");
            return;
        }
        const tempLocation = await uploadAnnotatedImage(props.timeline.eventGuid, markerImage);
        if (!tempLocation) {
            toast.error("Unable to save image Annotation");
            return;
        }
        const annotationData = {type: imageAnnotation.type, markerjs_json: {state: markerState, annotated_image_temp_loc: tempLocation} };
        if (imageAnnotation.id) {
            await eventService.editAnnotation(user.region, props.timeline.eventGuid, user.authId, imageAnnotation.id, annotationData)
            .then((result) => {
                toast.success('Image Annotation saved successfully');
            })
            .catch((e) => {
                console.error(e);
                toast.error("Unable to save image Annotation");
            });
        }
        else {
            await eventService.createAnnotation(user.region, props.timeline.eventGuid, user.authId, annotationData)
            .then((result) => {
                toast.success('Image Annotation saved successfully');
            })
            .catch((e) => {
                console.error(e);
                toast.error("Unable to save image Annotation");
            });
        }
        setShowImageAnnotationModal(false);

        //  Refresh Annotations
        getAllAnnotations();
    }

    const uploadAnnotatedImage = async (eventId, annotatedImage) => {
        if (eventId && annotatedImage) {
            const temp_loc = `temp/${uuid.v4()}`;
            const uploadUrl = `${apiService.getDataUrl(user.region)}/${user.mkey}/${temp_loc}`;
            await eventService.saveAnnotatedImage(uploadUrl, annotatedImage);
            return temp_loc;
        }
    }

    const setAnnotatedImage = (url) => {
        const filepath = `${apiService.getDataUrl(user.region)}/${user.mkey}/${url}`;
        const requestOptions = {
            method: 'GET',
            headers: {'Authorization': `Bearer ${(window.authToken)}`}
        }
        fetch(filepath, requestOptions).then((response)=>{
            response.json().then(dataUrl => {
                setTargetImageUrl(dataUrl);          
            });
        });
    }
  
    const toggleAOIBoundary = () => {
        setShowAOIBoundary(!showAOIBoundary);
    }

    const toggleAOIAnnotations = () => {
        props.setShowAOIAnnotations(!props.showAOIAnnotations);
        if (!props.showAOIAnnotations) {
            props.setSelectedDropdownAnnotationId("");
            props.setSelectedAnnotationId("");
        }
        updateThickness();
    }

    const toggleAOIImage = () => {
        const displayAOIImage = !showAOIImage;
        setShowAOIImage(displayAOIImage);  
    }

    const onSourceImgLoad = () => {
        if (props.showAOIAnnotations) {
            // When AOIImage is loaded, image will render on top of Annotations. So, annotations will be hidden
            // To avoid that, hide and re-render annotations after the image is renderred
            props.setShowAOIAnnotations(false);
            setReRenderAOIAnnotations(true);
        }
    };

    useEffect(() => {
        // 0 - Not Running
        // 1 - Running
        // 2 - Paused
        if(mapMoveState == 0 || mapMoveState == 2) {
            propagateCenterZoom();
            return;
        }

        if(mapMovementPointPosition == 0 && mapMoveState == 1) {
            if(props.setSelectedAnnotationId)
                props.setSelectedAnnotationId("");
            mapRef.current.setZoom(user?.userSettings?.dashboard?.zoomLevel ?? 16);
            updateThickness();
        }
        if(mapMovementPointPosition >= mapMovementPoints.length) {
            setMapMoveState(0);
            setMapMovementPointPosition(0);
            return;
        }

        const point = mapMovementPoints[mapMovementPointPosition];
        mapRef.current.panTo(new google.maps.LatLng(point.geometry.coordinates[1], point.geometry.coordinates[0]));

        return setTimeout(() => setMapMovementPointPosition(mapMovementPointPosition+1), 1000);
    }, [mapMovementPoints, mapMoveState, mapMovementPointPosition]);

    const propagateCenterZoom = () => {
        if(mapRef.current != null) {
            props.setCenter({lat: mapRef.current.getCenter().lat(), lng: mapRef.current.getCenter().lng()});
            props.setZoom(mapRef.current.zoom);
        }
    };

    const onDrawingComplete = (event) => {
        setOverlays(currentOverlays => [...currentOverlays, event.overlay]);
        console.log(event);
        let bounds = [];
        if (event.type == 'rectangle') {
            let p = event.overlay.getBounds();
            bounds.push([p.getNorthEast().lng(), p.getNorthEast().lat()]);
            bounds.push([p.getSouthWest().lng(), p.getNorthEast().lat()]);
            bounds.push([p.getSouthWest().lng(), p.getSouthWest().lat()]);
            bounds.push([p.getNorthEast().lng(), p.getSouthWest().lat()]);
        } else {
            let polygonBounds = event.overlay.getPath();
            for (let i = 0; i < polygonBounds.length; i++) {
                bounds.push([polygonBounds.getAt(i).lng(), polygonBounds.getAt(i).lat()]);
            }
        }
        setAnnotationMode('create');
        saveGeometryAnnotation([bounds]);
    };

    const clearPolygons = useCallback(() => {
        overlays.forEach(overlay => overlay.setMap(null));
        setOverlays([]);
      }, [overlays]);

    const cancelSaveAnnotation = () => {
        clearPolygons();
        setShowSaveAnnotationModal(false);
        setDrawingMode(null);
    };

    const gotoSearchLocation = async (searchLocation) => {
        try {
            if (searchLocation) {
                if(props.setSelectedAnnotationId)
                    props.setSelectedAnnotationId("");
    
                const results = await getGeocode({address: searchLocation});
                if (results.length > 0) {
                    const bounds = results[0].geometry?.bounds;
                    if(bounds) {
                        mapRef.current.fitBounds(bounds);
                    } else if(results[0].geometry?.viewport) {
                        mapRef.current.fitBounds(results[0].geometry?.viewport);
                    }
                    propagateCenterZoom();
                }
            }
        }
        catch (e) {
            console.log(`Error in Setting location in Map. Search location: ${searchLocation} Error: ${e}`);
            if (e.toString() == 'ZERO_RESULTS') {
                // No matching location(s) available for the given search Location
                toast.error('Please Enter a Valid location', 'fail');
            }
        }  
    };

    const deleteEvent = () => {
        eventService.deleteEvent(user.region, props.timeline.eventGuid)
            .then((result) => {
                props.refresh();
                toast.success("Successfully deleted the event");
            })
            .catch((error) => {
                console.error(error);
                toast.error("Unable to delete the event");
            });
    };

    const getMilestoneIcon = (number) => {
        var icon = `<svg width="24" height="40" viewBox="0 0 24 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 13.5427C0 6.71667 5.70988 1.27853 12.5278 1.61111V1.61111V1.61111C16.9821 1.28116 20.7778 4.80612 20.7778 9.2726V37.0556L1.5 39L0 35.2222V13.5427Z" fill="black"/>
        <path d="M22.0001 38.0556H23.0001V37.0556V12C23.0001 6.38512 18.4483 1.83334 12.8334 1.83334C7.21852 1.83334 2.66675 6.38512 2.66675 12V37.0556V38.0556H3.66675H22.0001Z" fill="white" stroke="black" stroke-width="2"/>
        <g filter="url(#filter0_d_2128_144)">
        <path d="M3.66675 12C3.66675 6.9374 7.7708 2.83334 12.8334 2.83334V2.83334C17.896 2.83334 22.0001 6.9374 22.0001 12V15.6667H3.66675V12Z" fill="#FED403"/>
        </g>
        <defs>
        <filter id="filter0_d_2128_144" x="3.66675" y="2.83334" width="18.3333" height="13.8333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2128_144"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2128_144" result="shape"/>
        </filter>
        </defs>
        <text x="50%" y="70%" dominant-baseline="middle" text-anchor="middle" fill="#000000" font-size="12">XXXYYYZZZ</text>
        </svg>`;

        return `data:image/svg+xml;utf8,${encodeURIComponent(icon.replace('XXXYYYZZZ', number))}`;
    };

    const onAnnotationDropdownChange = (navigation, selectedAnnotationId) => {
        let annotationToBeSelected = selectedAnnotationId;
        if (navigation == "prev") {
            annotationToBeSelected = allAnnotations[(allAnnotations.findIndex(annotation => annotation.id == props.selectedDropdownAnnotationId) ?? 0) - 1]?.id;
        }
        else if (navigation == "next") {
            annotationToBeSelected = allAnnotations[(allAnnotations.findIndex(annotation => annotation.id == props.selectedDropdownAnnotationId) ?? 0) + 1]?.id;
        }

        props.setSelectedDropdownAnnotationId(annotationToBeSelected);
        props.setSelectedAnnotationId(annotationToBeSelected);
    }

    return (
        <>
            {
                props.showToggleOptions && 
                <>
                    <div className='annotations-container'>
                        {
                            props.showAOIAnnotations &&
                            <div className='annotations-list'>
                                <a className={`annotations-prev ${allAnnotations.findIndex(annotation => annotation.id == props.selectedDropdownAnnotationId) == 0 ? 'disabled' : ''}`}
                                    onClick={(e) => onAnnotationDropdownChange("prev", null)}
                                ><i></i>
                                </a>
                                <select class="annotations-options" value={props.selectedDropdownAnnotationId} onChange={(e) => onAnnotationDropdownChange("selected", e.target.value)}>
                                    <option value="">{`${allAnnotations.length > 0 ? "Select Annotation" : "No Annotations Found"}`}</option>
                                {
                                    allAnnotations.filter(annotation=> 
                                        annotation.type == 'geometry' &&
                                        annotation.geometry?.coordinates[0]?.length > 0
                                    ).map((annotation) => (
                                        <option value={annotation?.id}>{annotation?.text}</option>
                                    ))
                                }
                                </select>
                                <a className={`annotations-next ${allAnnotations.findIndex(annotation => annotation.id == props.selectedDropdownAnnotationId) == (allAnnotations.length -1) ? 'disabled' : ''}`}
                                    onClick={(e) => onAnnotationDropdownChange("next", null)}
                                ><i></i>
                                </a>
                            </div>
                        }
                        
                        <a className={props.showAOIAnnotations ? "aoi-toggle show" : "aoi-toggle hide"} data-bs-toggle="tooltip" data-bs-placement="left"
                            title={props.showAOIAnnotations ? "Hide Annotations" : "Show Annotations"} onClick={toggleAOIAnnotations}>
                            <i></i>
                        </a>
                    </div>
                    <div className="aoi-tools">
                        {/* <a className="delete-annotations" title="Delete Event" onClick={deleteEvent}>Delete<i></i></a> */}

                        {/*  
                            <a className={props.showAOIAnnotations ? "aoi-toggle show" : "aoi-toggle hide"} data-bs-toggle="tooltip" data-bs-placement="left"
                                title={props.showAOIAnnotations ? "Hide Annotations" : "Show Annotations"} onClick={toggleAOIAnnotations}>
                                <i></i>
                            </a> 
                        */}
                        
                        <a className={showMilestones ? "aoi-milestone show" : "aoi-milestone hide"} 
                            title={showMilestones ? "Hide Milestones" : "Show Milestones"} onClick={() => setShowMilestones(!showMilestones)}>
                            <i></i>
                        </a>
                        {
                            Role.canEdit(user.role) &&
                            <>
                                <a className="test" onClick={() => setDrawingMode("polygon")}>
                                    <img src={PolygonIcon} alt="" />
                                </a>
                                <a className="test" onClick={() => setDrawingMode("rectangle")}>
                                    <img src={SquareIcon} alt="" />
                                </a>
                            </>
                        }
                        <a className="test" onClick={() => setDrawingMode(null)}>
                            <img src={HandIcon} alt="" />
                        </a>
                    </div>
                    <div className="aoi-tools recenter">
                        <a className="test" onClick={() => fitMap()} title='Re-center the map'>
                            <img src={RecenterIcon} alt="" />
                        </a>
                    </div>
                </>
            }
            {
                !props.hideLeft && 
                <LocationSearch hideCurrentLocation={true} gotoSearchLocation={gotoSearchLocation}/>
            }
            <GoogleMap
                center = {props.center}
                zoom={props.zoom}
                mapContainerStyle={AOIOptions.mapContainerStyle}
                options = {mapOptions}
                onLoad = {onMapLoad}
                onZoomChanged = {onZoomChange}
            >
                {
                    props.aoi && showAOIBoundary &&
                    <Polygon
                        key={props.aoi.member_aoi_id}
                        paths={MapUtils.geoJsonToCoordinates(props.aoi.aoiGeometry)}
                        options={aoiOptions}
                    />
                }
                <DrawingManager
                    onOverlayComplete = {onDrawingComplete}
                    options = {AOIOptions.defaultDrawingManagerOptions}
                    drawingMode={drawingMode}
                />
                {
                    props.aoi && props.aoi.rouGeometry && rou50mOptions.secondLine &&
                    <Polyline 
                        key="rou50mLineBorder"
                        path={MapUtils.geoJsonToCoordinates(props.aoi.rouGeometry)}
                        options={rou50mOptions.secondOptions}
                        />
                }
                {
                    props.aoi && props.aoi.rouGeometry &&
                    <Polyline 
                        key="rou50mLine"
                        path={MapUtils.geoJsonToCoordinates(props.aoi.rouGeometry)}
                        options={rou50mOptions.firstOptions}
                        />
                }
                {
                    props.aoi && props.aoi.pipelineGeometry && pipelineOptions.secondLine &&
                    <Polyline 
                        key="polylineBorder"
                        path={MapUtils.geoJsonToCoordinates(props.aoi.pipelineGeometry)}
                        options={pipelineOptions.secondOptions}
                        />
                }
                {
                    props.aoi && props.aoi.pipelineGeometry &&
                    <Polyline 
                        key="polyline"
                        path={MapUtils.geoJsonToCoordinates(props.aoi.pipelineGeometry)}
                        options={pipelineOptions.firstOptions}
                        />
                }
                {
                    sourceUrls && sourceUrls.map((image) => {
                        //console.log("Rendering ", image.url);
                        return <GroundOverlay
                                url={image.url}
                                key={image.url}
                                bounds={getCoordinates(image.bounds)}
                            />;
                    })
                }
                {/* {
                    props.timeline && targetImageUrl && showAOIAnnotations && 
                    <GroundOverlay
                        url={targetImageUrl}
                        key={targetImageUrl}
                        bounds={getCoordinates(props.timeline.result.bounds)}
                    />
                    */
                }
                {
                    // props.timeline && sourceImageUrl && showAOIImage &&
                    // <GroundOverlay
                    //     url={sourceImageUrl}
                    //     key={sourceImageUrl}
                    //     bounds={getCoordinates(props.timeline.result.bounds)}
                    //     onLoad={onSourceImgLoad}
                    // />
                }
                {
                    showMilestones && 
                    props.aoi.milestones.map((lnglat, index) => 
                        <Marker 
                            icon={getMilestoneIcon(index+1)}
                            key={lnglat[0]}
                            position={{lat: lnglat[1], lng: lnglat[0]}} 
                        />
                    )
                }
                { /* Saved Polygon Annotations */      
                    props.showAOIAnnotations && allAnnotations.filter(annotation=> 
                        annotation.type == 'geometry' &&
                        annotation.geometry?.coordinates[0]?.length > 0
                    ).map((annotation) => (
                        <Polygon
                            key={annotation.id}
                            paths = {getPathsFromGeometry(annotation?.geometry)}
                            options= {annotationsOptions}
                            onLoad={(geometry)=>onGeometryAnnotationLoad(geometry, annotation)}
                            onClick={()=>onGeometryAnnotationClick(annotation)}
                        />
                    ))
                }
                {
                    /* InfoWindow in Geometry Annotation */
                    props.showAOIAnnotations && allAnnotations.filter(annotation=> 
                        annotation.type == 'geometry' &&
                        annotation.geometry?.coordinates[0]?.length > 0
                    ).map((annotation) => (
                        <InfoWindow key={annotation.id}
                            position={getNorthEastFromGeometry(annotation?.geometry)}
                            onLoad={infoWindow => onInfoWindowLoad(infoWindow, annotation)}
                            options={{disableAutoPan: true}}                       
                        >
                            <div className="annotation-info">
                                <span className="text">{annotation?.text}</span>
                                <div className="actions">
                                    {
                                        Role.canEdit(user.role) && 
                                        <>
                                            <span><a className="delete-icon" title="Delete" onClick={() => deleteAnnotation(annotation.id)}><img src={DeleteIcon} /></a></span>
                                            <span><a className="edit-icon" title="Edit" onClick={() => onEditAnnotation(annotation)}><img src={EditAnnotationIcon} /></a></span>
                                        </>
                                    }
                                    <span><a className="text-link" onClick={() => props.setSelectedAnnotationId(annotation?.id)}><i className="zoom"></i></a></span>
                                </div>
                            </div>
                        </InfoWindow>
                    ))
                }
                {
                    /* Create Rectangle Annotation */
                    showCreateRectangleAnnotation &&
                    <Rectangle
                        bounds= {initialAnnotationGeometry}
                        options= {AOIOptions.createRectangleAnnotationOptions}
                        onLoad= {setGeometryAnnotationRef}
                    />
                }              
            </GoogleMap>
            { 
                showSaveAnnotationModal && 
                <SaveAnnotationModal 
                    setShowSaveAnnotationModal={setShowSaveAnnotationModal} 
                    saveAnnotation={saveAnnotation}
                    annotation={annotation}
                    annotationMode={annotationMode}
                    cancel={cancelSaveAnnotation}
                />
            }
            { 
                showImageAnnotationModal && 
                <ImageAnnotationModal 
                    timeline={props.timeline}
                    setShowImageAnnotationModal={setShowImageAnnotationModal} 
                    annotation={imageAnnotation}
                    sourceImageUrl={sourceImageUrl}
                    targetImageUrl={targetImageUrl}
                    saveImageAnnotation={saveImageAnnotation}
                    deleteAnnotation={deleteAnnotation}
                />
            }
        </>
    );
}

export default SingleTimeline;
